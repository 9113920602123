<template>
  <div id="app">
    <router-view v-if="loadUserInfo" />
  </div>
</template>

<script>
import initialData from "@/utils/initialData";
import asyncRouter from "@/router/asyncRouter";
import watermark from "@/utils/watermark";
import { getToken } from "@/utils/auth"
export default {
  data () {
    return {
      loadUserInfo: false
    }
  },
  mounted () {
    let token = getToken()
    let userInfo = localStorage.getItem("userInfo")
    if (token && userInfo) {
      let userInfoData = JSON.parse(userInfo)
      this.$store.commit('getUserInfo', userInfoData)
      watermark.set(userInfoData.userName)
      this.$api.getMenu().then(res => {
        this.$store.commit('getPermissions', res.data.data)
        asyncRouter()
        initialData()
      })
    } else {
      this.$router.push("/login")
    }
    this.$nextTick(() => {
      this.loadUserInfo = true
    })
  },
}
</script>

<style src="./assets/css/reset.css"></style>
<style src="./assets/css/global.css"></style>
<style lang="scss" src="./assets/css/common.scss"></style>
<style lang="scss" >
#app {
  // min-width: 1200px !important;
  height: 100%;
}
</style>
