export default [
	{
		path: '/feedback',
		name: 'feedback',
		meta: {
			title: '意见反馈'
		},
		component: () => import('@/views/feedback/index.vue')
	},
	{
		path: '/coursePower',
		name: 'coursePower',
		meta: {
			title: '课程权限'
		},
		hidden: false,
		component: () => import('@/views/system/coursePower/index')
	}
]
