import {api} from "@/http/http.api";
import processSubjectTree from "@/utils/processSubjectTree";

export default {

    namespaced: true,

    state: {
        menuList: [],
        areaList:[],//地址列表
        courseTypeList: [], //课程分类
        courseFieldList: [],//课程领域
        courseLevelList: [],//课程等级
        ageScopeList: [], //年龄阶段
        activityTypeList: [], //活动分类
        courseVersionList: [], //课程版本
        courseThemeList: [], //课程专题
        isCollapse: false,
    },

    mutations: {
        getMenuList(state, data) {
            state.menuList = data
        },
        getAreaList(state,data){
            state.areaList=data
        },
        getCourseTypeList(state, data) {
            state.courseTypeList = data
        },
        getCourseFieldList(state, data) {
            state.courseFieldList = data
        },
        getCourseLevelList(state, data) {
            state.courseLevelList = data
        },
        getAgeScopeList(state, data) {
            state.ageScopeList = data
        },
        getActivityTypeList(state, data) {
            state.activityTypeList = data
        },
        getCourseVersionList(state, data) {
            state.courseVersionList = data
        },
        getCourseThemeList(state, data) {
            state.courseThemeList = data
        },
        getIsCollapse(state, data) {
            state.isCollapse = data
        },
    },

    actions: {
        //仓库列表
        getMenuList(ctx) {
            api.getMenu().then(res => {
                ctx.commit('getMenuList', res.data.data)
            })
        },
        // 地址列表
        getAreaList(ctx){
            api.ecsAreaList().then(res => {
                processSubjectTree(res.data.data.children)
                ctx.commit('getAreaList', res.data.data.children)
            })
        },
        // 课程分类
        getCourseTypeList(ctx){
            api.getDictionary({ type: 'class_classify' }).then(res => {
                ctx.commit('getCourseTypeList', res.data.data)
            })
        },
        // 课程领域
        getCourseFieldList(ctx){
            api.getDictionary({ type: 'class_field' }).then(res => {
                ctx.commit('getCourseFieldList', res.data.data)
            })
        },
        // 课程等级
        getCourseLevelList(ctx){
            api.getDictionary({ type: 'class_level' }).then(res => {
                ctx.commit('getCourseLevelList', res.data.data)
            })
        },
        // 年龄阶段
        getAgeScopeList(ctx){
            api.getDictionary({ type: 'age_scope' }).then(res => {
                ctx.commit('getAgeScopeList', res.data.data)
            })
        },
        // 活动类型
        getActivityTypeList(ctx){
            api.getDictionary({ type: 'activity_classify' }).then(res => {
                ctx.commit('getActivityTypeList', res.data.data)
            })
        },
        // 课程版本
        getCourseVersionList(ctx){
            api.getDictionary({ type: 'class_version' }).then(res => {
                ctx.commit('getCourseVersionList', res.data.data)
            })
        },
        // 课程专题
        getCourseThemeList(ctx){
            api.getDictionary({ type: 'class_theme' }).then(res => {
                ctx.commit('getCourseThemeList', res.data.data)
            })
        }
    }

}
