// 库存管理模块api
import http from "../http.interceptors";

const systemApi = {
    /*
        应用系统管理
     */
    //首页图标
    getBusinessList:()=>http.get('/sszagoods/sszabusiness/list'),
    //列表
    getBusiness: params=>http.get('/sszagoods/sszabusiness/page',{params}),
    //详情
    getBusinessDetail:id=>http.get('/sszagoods/sszabusiness/'+id+''),
    //新增
    addBusiness: params=>http.post('/sszagoods/sszabusiness',params),
    //修改
    editBusiness: params=>http.put('/sszagoods/sszabusiness',params),
    //删除
    delBusiness: id=>http.delete('/sszagoods/sszabusiness/'+id+''),
// 获取往来单位列表
    getcompanyd: data => http.get('/admin/dept/all-tree', { params: data }),
    //用户管理
    fetchListUser: data => http.get('/admin/user/page', { params: data }), // 列表
    fetchDeptTreeUser: data => http.get('/admin/dept/user-tree', { params: data }), // 树形数据/admin/role/list
    deptRoleListUser: () => http.get('/admin/role/list'), // 角色
    addObjUser: data => http.post('/admin/user', data),
    delObjUser: data => http.delete('/admin/user/' + data),
    getObjUser: data => http.get('/admin/user/' + data),
    putObjUser: data => http.put('/admin/user', data),
    getDetailsUser: data => http.get('/admin/user/details/' + data),
    //部门管理
    addObjRole: data => http.post('/admin/role', data),
    delObjRole: data => http.delete('/admin/role/' + data),
    fetchListRole: data => http.get('/admin/role/page', { params: data }), // 列表
    fetchRoleTreeRole: data => http.get('/admin/menu/tree/' + data),
    getObjRole: data => http.get('/admin/role' + data),
    permissionUpdRole: (roleId, menuIds) => http.put('/admin/role/menu?roleId=' + roleId + '&menuIds=' + menuIds),
    putObjRole: data => http.put('/admin/role', data),
    //菜单管理
    addObjMenu: data => http.post('/admin/menu', data),
    delObjMenu: data => http.delete('/admin/menu/' + data),
    fetchTree: data => http.get('/admin/menu/tree', { params: data }),
    getObjMenu: data => http.get('/admin/menu/' + data),
    putObjMenu: data => http.put('/admin/menu', data),
    //门户-更改密码、手机号
    userEdit: data => http.put('/admin/user/edit', data),
    //门户-消息列表
    getMyNotice: data => http.get('/sszastock/notice/getMyNotice', {params: data}), // 列表
    //门户-任务查看
    readNotice: params => http.post('/sszastock/notice/readNotice', params), // 详情
    // 部门管理-树形菜单
    fetchDeptTree: data => http.get('/admin/dept/tree', { params: data }),
    // 部门管理-添加
    addObj: params => http.post('/admin/dept/', params),
    // 部门管理-删除
    delObj: data => http.delete('/admin/dept/' + data),
    // 部门管理-更新
    putObj: params => http.put('/admin/dept/', params),
    // 登录日志管理-列表
    fetchLoginLogList: data => http.get('/api/eduLog/getLoginLog', { params: data }),
	// 登录日志管理-列表
	fetchHandleLogList: data => http.get('/api/eduLog/page', { params: data }),
    // 日志管理-删除
    delLog: data => http.delete('/admin/log/' + data),
    // 字典管理-列表
    fetchListDict: data => http.get('/api/edudict/search', { params: data }),
    // 字典管理-更新
    putObjDict: params => http.put('/api/edudict', params),
    // 字典管理-删除
    delDict: data => http.delete('/api/edudict/' + data.id),
    // 字典管理-添加
    addObjDict: params => http.post('/api/edudict', params),
    // 数据权限-label查询权限
    getByDeptLabel: params => http.get('/api/eduauthdata/getByDeptLabel', {params}),
    // 操作日志-查询所属模块
    getLogModule: params => http.get('/api/eduLog/getLogModule', { params }),
    // 反馈
    getTeacherFeedback: params => http.get('/api/edusystemtoteacher/getTeacherFeedbackSearch', { params }),
    // 查询课程权限
    getCoursePower: params => http.get('/api/educourse/auth/page', { params }),
    // 修改课程权限
    updateCoursePower: params => http.post('/api/educourse/saveCourseAuth', params),
}

export {systemApi}
