<template>
  <div class="login-page"
       :style="note">
    <div class="login-wrap"
         :style="loginWarp">
      <div class="img-wrap">
        <img src="../../assets/img/login-right-img.png"
             alt="" />
      </div>
      <div class="login-form-wrap">
        <div class="login-form-center">
          <div class="login-title">
            <!-- <p class="login-title-desc">Integrated management system user login</p> -->
            <p class="login-title-text">建业教育知识库平台用户登录</p>
          </div>
          <div class="login-form">
            <div>
              <input class="login-input"
                     placeholder="请输入您的手机号"
                     v-model.trim="loginForm.username"
                     maxlength="20" />
            </div>
            <div>
              <input class="login-input"
                     type="password"
                     placeholder="请输入您的密码"
                     maxlength="20"
                     v-model.trim="loginForm.password" />
            </div>
            <div class="login-code">
              <label>
                <input class="login-input login-validate"
                       placeholder="请输入验证码"
                       v-model.trim="loginForm.code"
                       maxlength="4" />
              </label>
              <div class="login-code-img">
                <img v-if="code.src"
                     :src="code.src"
                     alt=""
                     @click="refreshCode">
              </div>
            </div>
            <div class="auto-pass">
              <label class="auto-login">
                <input type="checkbox"
                       v-model="autoPass" />
                记住密码
              </label>
              <!-- <p class="pass-forget">忘记密码</p> -->
            </div>
          </div>
          <button class="login-btn"
                  v-loading="buttonLoading"
                  @click="handleLogin">登 录</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import randomLenNum from "@/utils/randomLenNum";
// import encryption from "@/utils/encryption";
import qs from "qs";
import { setToken } from "@/utils/auth";
import asyncRouter from "@/router/asyncRouter";
import initialData from "@/utils/initialData";
import watermark from "@/utils/watermark";

export default {
  data () {
    return {
      note: {
        backgroundImage:
          "url(" + require("../../assets/img/login-bg-img.png") + ")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        marginTop: "0",
      },
      loginWarp: {
        backgroundImage:
          "url(" + require("../../assets/img/login-bg-left-img.png") + ")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        backgroundColor: '#fff'
      },
      code: {
        src: "/code",
        len: 4,
        type: "image",
        key: ""
      },
      randomStr: null,
      loginForm: {
        username: "",
        password: "",
        code: "",
        key: "",
      },
      autoPass: false,
      buttonLoading: false, //按钮loading
    };
  },
  created () {
    let _this = this;
    document.onkeydown = function (e) {
      if (e.keyCode === 13) {
        _this.handleLogin(); //自己写的登录方法，点击事件
      }
    };
  },
  mounted () {
    this.refreshCode()
  },
  methods: {
    handleLogin () {
      const loginForm = this.loginForm;
      if (!loginForm.username) {
        this.$message.warning("请输入用户名");
        return false;
      }
      if (!loginForm.password) {
        this.$message.warning("请输入密码");
        return false;
      }
      if (loginForm.password.length < 6) {
        this.$message.warning("密码长度最小为6位");
        return false;
      }
      if (!loginForm.code) {
        this.$message.warning('请输入验证码')
        return false
      }

      this.loginByUsername();
    },
    loginByUsername () {
      this.buttonLoading = true
      const loginForm = this.loginForm;

      // const user = encryption({
      //   data: loginForm,
      //   key: 'blsframework1.00',
      //   param: ['password']
      // })

      const params = {
        phone: loginForm.username,
        password: loginForm.password,
        key: this.code.key,
        code: loginForm.code,
        // grant_type: 'password',
        // scope: 'server'
      };
      this.$api.loginByUsername(params).then((res) => {
        this.buttonLoading = false
        if (res.data.code === 0) {
          this.$message.success("登录成功");
          setToken(res.data.data.token, this.autoPass);

          watermark.set(res.data.data.userName);
          this.$store.commit("getUserInfo", res.data.data);
          localStorage.setItem("userInfo", JSON.stringify(res.data.data));
          this.$api.getMenu().then((res) => {
            this.$store.commit("getPermissions", res.data.data);
            asyncRouter()
            initialData();
            this.$router.push("/");
          });
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    refreshCode () {
      this.loginForm.code = "";
      this.$api.getAuthCode({}).then(res => {
        console.log(res);
        if (res.data.code === 0) {
          this.code.src = res.data.data.image
          this.code.key = res.data.data.key
        } else {
          this.$message.error(res.data.msg)
        }
      }).catch(c => {
        console.log(c);
      })
    },
  },
  beforeDestroy () {
    document.onkeydown = null
  }
};
</script>
<style lang="scss" scoped>
.login-page {
  width: 100%;
  height: 100%;
  // min-height: 700px;
  display: flex;
  justify-content: center;
  align-items: center;

  .login-wrap {
    width: 67.7083333vw;
    height: 61.759259vh;
    display: flex;
    align-items: center;
    box-shadow: 0px 3px 98px rgba(89, 126, 247, 0.3);
    border-radius: 35px;

    .img-wrap {
      width: 50%;
      height: calc(100% - 2.604167vw);
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      padding: 0 0 5.16vh 2.604167vw;
      img {
        width: 26.71875vw;
        height: 43.240740741vh;
        //height: 79.538462%; //517px
      }
    }

    .login-form-wrap {
      padding: 0 3.125vw;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      .login-form-center {
        width: 29vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
      }

      .login-title {
        margin-bottom: 8%;

        .login-title-desc {
          font-size: 0.5em;
          line-height: 1em;
          letter-spacing: -1px;
          color: #a3a3a3;
        }

        .login-title-text {
          font-size: 1.770833333vw;
          line-height: 1.4;
          color: #597ef7;
          font-weight: bold;
        }
      }

      .login-form {
        label {
          width: 60%;
          display: block;
        }
        .login-input {
          display: block;
          width: 23.125vw;
          height: 2.5vw;
          border-radius: 32px;
          outline: none;
          border: none;
          box-sizing: border-box;
          padding-left: 30px;
          background-color: #f6f7fb !important;
          color: #7d7d7d;
          font-size: 0.8em;
          margin-bottom: 8%;
        }

        .login-user {
          background-image: url('../../assets/img/login-user.png');
        }

        .login-pass {
          background-image: url('../../assets/img/login-pass.png');
        }

        // .login-code input {
        //   background: url("../../assets/img/iconyanzhengma.png") no-repeat 20px
        //     center;
        // }

        .login-validate {
          width: 100%;
          margin-bottom: 0;
        }

        .auto-pass {
          display: flex;
          justify-content: space-between;
          margin-top: 4.307692%;

          .pass-forget {
            color: #3967ff;
            font-size: 0.5em;
            line-height: 1;
          }

          .auto-login {
            line-height: 1;
            color: #597ef7;
            font-size: 0.8em;
          }
        }
      }

      .login-code {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .login-code-img {
          height: 2.5vw;
          img {
            height: 100%;
          }
        }
      }

      .login-btn {
        width: 23.125vw;
        height: 2.5vw;
        box-sizing: border-box;
        border: none;
        color: #fff;
        font-size: 1em;
        border-radius: 32px;
        margin-top: 10%;
        outline: none;
        cursor: pointer;
        background: linear-gradient(
          180deg,
          #7561fe 0%,
          #597ef7 99%,
          #0c40c7 100%
        );
        box-shadow: 2px 8px 9px rgba(138, 138, 138, 0.12);
      }
    }
  }
}
</style>
