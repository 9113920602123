/*
    系统设置路由
 */
export default [
  {
    path: '/system',
    meta: {
      title: '系统管理'
    },
    component: () => import('@/layout/Main'),
    hidden: false,
    children: [
      { // 日志管理
        path: '/log',
        name: 'log',
        meta: {
          title: '日志管理'
        },
        hidden: false,
        component: () => import('@/views/system/log/logManger.vue')
      },
      { // 字典管理
        path: '/dict',
        name: 'dict',
        meta: {
          title: '字典管理'
        },
        hidden: false,
        component: () => import('@/views/system/dict/dict.vue')
      },
      { // 部门管理
        path: '/dept',
        name: 'dept',
        meta: {
          title: '数据权限'
        },
        hidden: false,
        component: () => import('@/views/system/dept/dept.vue')
      },
      // {
      //   path: '/coursePower',
      //   name: 'coursePower',
      //   meta: {
      //     title: '课程权限'
      //   },
      //   hidden: false,
      //   component: () => import('@/views/system/coursePower/index')
      // }
    ]
  }
]
