// 库存管理模块api
import http from "../http.interceptors";

const courseApi = {
    /*
        课程管理
     */
    //获取课程管理列表
    getCourseList: params=>http.get('/api/educourse/page',{params}),
    //添加/编辑课程
    courseHandle: (url, params, type) => type == 'add' ? http.post(url, params) : http.put(url, params),
    //删除课程
    delCourse: (params) => http.put("/api/educourse/del", params),
    //获取课程详情
    getCourseInfo: id => http.get("/api/educourse/" + id),
    //内容设置请求
    contentHandle: (url, params, type) => {
      let fun = null
      switch(type){
        case 'list':
          fun = http.get(url,{params})
          break;
        case 'add':
          fun = http.post(url, params)
          break;
        case 'edit':
          fun = http.put(url, params)
          break;
        case 'move':
          fun = http.put(url, params)
          break;
        case 'del':
          fun = http.put(url, params)
          break;
      }

      return fun
    },

    //获取课程的介绍内容详情
    getCourseIntroInfo: params=>http.get('/api/educourseintro/content/list',{params}),
    //课程介绍附件请求
    introContentFileHandle: (url, params, type) => {
      let fun = null
      switch(type){
        case 'add':
          fun = http.post(url, params)
          break;
        case 'edit':
          fun = http.put(url, params)
          break;
        case 'del':
          fun = http.put(url, params)
          break;
      }

      return fun
    },
    //课程介绍图文请求
    introContentHandle: (url, params, type) => {
      let fun = null
      switch(type){
        case 'add':
          fun = http.post(url, params)
          break;
        case 'edit':
          fun = http.put(url, params)
          break;
        case 'del':
          fun = http.put(url, params)
          break;
      }

      return fun
    },
    //课程介绍视频请求
    introVideoHandle: (url, params, type) => {
      let fun = null
      switch(type){
        case 'add':
          fun = http.post(url, params)
          break;
        case 'edit':
          fun = http.put(url, params)
          break;
        case 'del':
          fun = http.put(url, params)
          break;
      }

      return fun
    },

    //获取课程内容列表
    getCourseContentList: params=>http.get('/api/educoursetheme/page',{params}),
    //课程内容添加/编辑/删除请求
    courseContentHandle: (url, params, type) => {
      let fun = null
      switch(type){
        case 'add':
          fun = http.post(url, params)
          break;
        case 'edit':
          fun = http.put(url, params)
          break;
        case 'del':
          fun = http.put(url, params)
          break;
      }

      return fun
    },
    //根据id查询课程内容基本信息
    getCourseContentInfo: id => http.get('/api/educoursetheme/'+id),
    //获取课程内容目录列表
    getCourseContentCataLogList: params => http.get('/api/educoursethemecatalogue/tree/list', { params }),
    //课程内容目录添加/编辑/删除请求
    courseContentCataLogHandle: (url, params, type) => {
      let fun = null
      switch(type){
        case 'add':
          fun = http.post(url, params)
          break;
        case 'edit':
          fun = http.put(url, params)
          break;
        case 'del':
          fun = http.put(url, params)
          break;
        case 'move':
          fun = http.put(url, params)
          break;
      }

      return fun
    },
    //课程内容的指标设置请求
    contentTargetHandle: (url, params, type) => {
      let fun = null
      switch(type){
        case 'list':
          fun = http.get(url,{params})
          break;
        case 'add':
          fun = http.post(url, params)
          break;
        case 'edit':
          fun = http.put(url, params)
          break;
        case 'move':
          fun = http.put(url, params)
          break;
        case 'del':
          fun = http.delete(url+params)
          break;
      }

      return fun
    },

    //获取课程内容目录内容详情
    getCourseContentCatalogInfo: params => http.get('/api/educoursethemecatalogue/list/detail', { params }),
    //课程内容的目录内容的附件请求
    contentCatalogContentFileHandle: (url, params, type) => {
      let fun = null
      switch(type){
        case 'add':
          fun = http.post(url, params)
          break;
        case 'edit':
          fun = http.put(url, params)
          break;
        case 'del':
          fun = http.put(url, params)
          break;
      }

      return fun
    },
    //课程内容的目录内容的图文请求
    contentCatalogContentHandle: (url, params, type) => {
      let fun = null
      switch(type){
        case 'add':
          fun = http.post(url, params)
          break;
        case 'edit':
          fun = http.put(url, params)
          break;
        case 'del':
          fun = http.put(url, params)
          break;
      }

      return fun
    },
    //课程内容的目录内容的视频请求
    contentCatalogVideoHandle: (url, params, type) => {
      let fun = null
      switch(type){
        case 'add':
          fun = http.post(url, params)
          break;
        case 'edit':
          fun = http.put(url, params)
          break;
        case 'del':
          fun = http.put(url, params)
          break;
      }

      return fun
    },
    //课程内容的目录内容的活动请求
    contentCatalogActivityHandle: (url, params, type) => {
      let fun = null
      switch(type){
        case 'list':
          fun = http.get(url,{params})
          break;
        case 'add':
          fun = http.post(url, params)
          break;
        case 'edit':
          fun = http.put(url, params)
          break;
        case 'del':
          fun = http.put(url, params)
          break;
      }

      return fun
    },
    //获取指标库下拉
    getTargetArmOption: params=>http.get('/api/edutargetguide/list',{params}),
    //根据指标库id获取目标下拉
    getTargetOption: id=>http.get('/api/edutargetinfo/getEduTargetInfoByGuideId?guideId='+id),
    //根据目标维度以及维度字典值获取指标内容下拉
    getTargetContentOption: params=>http.get('/api/edutargetdescription/getListByTargetInfo',{params}),

    //获取目标的教学建议
    getTargetInfoDetailByCatalogueId: params=>http.get('/api/edutargetinfo/getTargetInfoDetailByCatalogueId',{params}),
    //获取目标的教学实践标签list
    getTargetInfoByDict: params=>http.get('/api/edutargetcatalogue/getTargetInfoByDict',{params}),
    //获取目标的教学实践标签下的实践信息
    getTargetInfoByPracticeTag: params=>http.get('/api/edutargetinfo/getTargetInfoByPracticeTag',{params}),
}

export { courseApi }