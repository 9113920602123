import Vue from 'vue'
import Vuex from 'vuex'
import {api} from "@/http/http.api";
import common from './modules/common'
import stock from "./modules/stock";
import goods from "@/store/modules/goods";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    keepAlive: [],
    notice: 0,
    userInfo: {
      roles: [],
      sysUser: {
        userId: "",
        username: "",
        avatar: "",
        warehouseCode: null,
        warehouseId: null,
        warehouseName: null
      }
    },
    permissions: [],
    tagViewList: [],
    userList: [], // h5-添加合伙人数组
    adminList: [], //h5-库管
    rowList: null, //h5-行数据
    selectList: [], //h5-汇总列表
    // h5订单类型
    type: {
      1: '入库任务',
      2: '生产任务',
      // 3: '装箱任务',
      4: '装车任务',
      5: '通用任务',
      6: '返库任务',
      7: '卸车任务'
    },
    taskType: {
      1: '调拨出库',
      2: '调拨入库',
      3: '采购入库',
      4: '采购退货',
      5: '销售出货',
      6: '销售退货',
    },
    statusType: {
      1: '未领取',
      2: '未完成',
      3: '已完成',
      4: '已退单',
      5: '已关闭',
    },
    optionsType: [
      {value: '', label: '全部类型'},
      {value: "1", label: "入库任务"},
      {value: "2", label: "生产任务"},
      {value: '3', label: '装箱任务'},
      {value: "4", label: "装车任务"},
      {value: "5", label: "通用任务"},
      {value: "6", label: "返库任务"},
      {value: "7", label: "卸车任务"},
    ],
  },
  getters: {
    permissions: state => state.permissions,
  },
  mutations: {
    getUserInfo(state, data) {
      state.userInfo = data
      //TODO 先做个假的
      // state.userInfo.sysUser.warehouseCode = '001'
      // state.userInfo.sysUser.warehouseId = '1'
      // state.userInfo.sysUser.warehouseName = '左岸仓库'
    },
    getPermissions(state, data) {
      state.permissions = data
    },
    setUserList(state, userList) {
      state.userList = [{
        userId: state.userInfo.sysUser.userId,
        userName: state.userInfo.sysUser.username
      }]
      if (userList.length) {
        state.userList = state.userList.concat(userList)
      }
    },
    setIndexStatus(state, adminList) {
      state.adminList = adminList;
    },
    setRowList(state, list) {
      state.rowList = list;
    },
    setSelectList(state, list) {
      state.selectList = list;
    },
    setTagViewList(state, list) {
      this.state.tagViewList = list
    }
  },
  actions: {
    getUserInfo(ctx) {
      api.getUerInfo().then(res => {
        ctx.commit('getUserInfo', res.data.data)
      }).catch(() => {
        ctx.commit('getUserInfo', null)
      })
    },
    setUserList({commit}, userList) {
      commit('setUserList', userList)
    },
    setIndexStatus({commit}, adminList) {
      commit('setIndexStatus', adminList)
    },
    setRowList({commit}, list) {
      commit('setRowList', list)
    },
    setSelectList({commit}, selectList) {
      commit('setSelectList', selectList);
    }
  },
  modules: {
    common,
    stock,
    goods
  }
})
