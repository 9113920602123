import http from "./http.interceptors";
import {systemApi} from "./modules/http.api.system";
import { courseApi } from "./modules/http.api.course";
import targetApi from "./modules/http.api.target";

const url = {
    codeUrl: '/code?randomStr=',
    getUploadUrl: '/sszagoods/goodsImg/getUploadUrl',
}

for (let key in url) {
    url[key] = process.env.VUE_APP_LOCAL_URL + url[key]
}

// url.imgUrl='http://10.86.3.202:8080'
// url.imgUrl=''
url.imgUrl=process.env.VUE_APP_IMG_URL

const api = {
    ...systemApi,
    ...courseApi,
    ...targetApi,

    getDictionary: params => http.get('/api/edudict/list', { params }),
    getSearchResourseDictionary: params => http.get('/api/edudict/listByEduCourseScopeLevel', { params }),
    getSearchResourseVersions: params => http.get('/api/edudict/listByEduCourseVersion', { params }),
    getSearchResourseTheme: params => http.get('/api/edudict/listByEduCourseTheme', { params }),
    //获取菜单权限
    getMenu: () => http.get('/api/edulogin/menu'),
    //获取用户信息
    getUerInfo: () => http.get('/admin/user/info'),
    //用户名登录
    loginByUsername: params => http.post('/api/eduloginuser/login', params),
    // 数据权限
    getDept: params => http.get('/api/edudept/deptList', {params}),
    //权限修改
    eduauthData: params => http.post('/api/eduauthdata', params),
    // 修改密码
    userEdit: params => http.post('/api/edulogin/update/pwd', params),
    //获取校验码
    getAuthCode: params => http.get('/api/eduloginuser/getCode', {params}),
    //上传方法
    importImgFile: (url,file) => {
        let forms = new FormData()
        let configs = {
            headers:{'Content-Type':'application/x-www-form-urlencoded'},
        };
        forms.append('file',file)

      return http.post(url,forms,configs)
    }
}

export { api, url }
