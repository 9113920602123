import http from '../http.interceptors';
const target = {
	//指标库列表
	getTargetList: params => http.get('/api/edutargetguide/page', { params }),
	//获取指标库单个数据
	getTarget: id => http.get('/api/edutargetguide/' + id),
	//指标库添加
	postTargetItem: params => http.post('/api/edutargetguide', params),
	//指标库编辑
	putTargetItem: params => http.put('/api/edutargetguide', params),
	//指标库删除
	deleteTargetItem: id => http.delete('/api/edutargetguide/' + id),
	//指标库详情
	getTargetItemDetails: id => http.get('/api/edutargetguide/' + id),
	//指标库配置-目录查询
	getTargetDeployDirList: params => http.get('/api/edutargetcatalogue/getEdutargetCatalogueList', { params }),
	//指标库配置-目录添加
	postTargetDeployDirInsert: params => http.post('/api/edutargetcatalogue', params),
	//指标库配置-目录修改
	putTargetDeployDirUpdate: params => http.put('/api/edutargetcatalogue', params),
	//指标库配置-目录删除
	deleteTargetDeployDir: params => http.delete('/api/edutargetcatalogue/' + params ),
	//指标库配置-目标-添加教育建议
	postTargetSuggestInsert: params => http.post('/api/edutargetsuggest', params),
	//指标库配置-目标-修改教育建议
	putTargetSuggestUpdate: params => http.put('/api/edutargetsuggest', params),
	//指标库配置-目标-删除教育建议
	deleteTargetSuggest: id => http.delete('/api/edutargetsuggest/' + id),
	//指标库配置-目标-查询多级教育建议（通过目标目录ID
	getTargetMultistageSuggest: params => http.get('/api/edutargetsuggest/getLevelListByTargetCatalogueId', { params }),
	//指标库配置-目标查询
	getTargetMultistageSuggestDetails: params => http.get('/api/edutargetinfo/getTargetInfoStep2ByCatalogueId', { params }),
	//指标库配置-目标详情 点击维度切换 描述/标签/实践/字典
	getTargetMultistageSuggestDetails_2: params => http.get('/api/edutargetinfo/getTargetInfoByFlag', { params }),

	getTargetCatalogue: params => http.get('/api/edutargetcatalogue/getEdutargetCatalogueList', { params }),
	//指标库配置-目标-保存第一步
	postTargetSaveNext: params => http.post('/api/edutargetcatalogue/saveTargetInfoAndUpdateSuggest', params),
	//获取指定类型字典
	getEduDict: params => http.get('/api/edudict/list', { params }),
	//添加目标描述
	postTargetDescription: params => http.post('/api/edutargetdescription', params),
	//修改目标描述
	putTargetDescription: params => http.put('/api/edutargetdescription', params),
	//删除目标描述
	deleteTargetDescription: id => http.delete('/api/edutargetdescription/' + id),
	//添加标签
	postTargetTag: params => http.post('/api/edutargetpracticetag', params),
	//修改标签
	putTargetTag: params => http.put('/api/edutargetpracticetag', params),
	//删除标签
	deleteTargetTag: id => http.delete('/api/edutargetpracticetag/' + id),
	//教育实践查询，由实践标签控制
	getTargetTagPractice: params => http.get('/api/edutargetinfo/getTargetInfoByPracticeTag', { params }),
	//教育实践指标描述字典标签切换
	getTargetTagDict: params => http.get('/api/edutargetinfo/getTargetInfoByDict', { params }),
	//切换教育实践 点击标签
	getTargetEducationalPractice: params => http.get('/api/edutargetinfo/getTargetInfoByPracticeTag', { params }),
	//教育实践添加
	postTargetPracticeInsert: params => http.post('/api/edutargetpractice', params),
	//教育实践修改
	putTargetPracticeUpdate: params => http.put('/api/edutargetpractice', params),
	//教育实践删除
	deleteTargetPractice: id => http.delete('api/edutargetpractice/' + id),
	//文件上传（附件
	uploadFile: params => http.post('/api/upload/uploadFileToOSS', params),
	//文件上传（视频）
	uploadFileVideo: params => http.post('/api/upload/uploadVideoToOSS', params),
	//文件删除（附件
	deleteFile: id => http.delete('/api/edutargetpracticefile/' + id),
	//获取目标详情(通过目录目标获取)
	getTargetDetails: params => http.get('/api/edutargetinfo/getTargetInfoDetailByCatalogueId', { params }),
	//指标目录上移下移
	postTargetSort: params => http.post('/api/edutargetcatalogue/move', params),
	//指标库上移下移
	postEdutargetGuideSort: params => http.post('/api/edutargetguide/move', params),
}

export default target;
