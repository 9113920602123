/*
    知识库路由
 */
export default [
  { // 目标指南
    path: '/targetManage',
    name: 'targetManage',
    meta: {
      title: '目标管理'
    },
    hidden: false,
    component: () => import('@/views/knowledgeBase/targetManage/index.vue')
  },
  { // 目标指南-编辑
    path: '/targetCollocate/:id',
    name: 'targetCollocate',
    meta: {
      title: '指标配置',
    },
    hidden: true,
    component: () => import('@/views/knowledgeBase/targetManage/targetCollocate.vue')
  },
  // {
	// 	path: '/feedback',
	// 	name: 'feedback',
	// 	meta: {
	// 		title: '意见反馈'
	// 	},
	// 	hidden: false,
	// 	component: () => import('@/views/feedback/index.vue')
	// },
  // { // 课程管理
  //   path: '/courseManage',
  //   meta: {
  //     title: '课程管理'
  //   },
  //   component: () => import('@/layout/Main'),
  //   hidden: false,
  //   children: [

  //   ]
  // },
  { // 课程管理-草稿箱
    path: '/courseManage',
    name: 'courseManage',
    meta: {
      title: '课程列表'
    },
    hidden: false,
    component: () => import('@/views/knowledgeBase/courseManage/list.vue')
  },
  { // 课程管理-编辑
    path: '/courseManage/edit',
    name: 'courseManageEdit',
    meta: {
      title: '课程管理-编辑'
    },
    hidden: true,
    component: () => import('@/views/knowledgeBase/courseManage/edit.vue')
  },
  { // 课程管理-目录设置编辑
    path: '/courseManage/catalogSetting',
    name: 'courseManageCatalogSetting',
    meta: {
      title: '课程管理-目录设置'
    },
    hidden: true,
    component: () => import('@/views/knowledgeBase/courseManage/courseCatalogSetting.vue')
  },
  { // 课程管理-目录设置编辑
    path: '/courseManage/contentSetting',
    name: 'courseManageContentSetting',
    meta: {
      title: '课程管理-内容详情'
    },
    hidden: true,
    component: () => import('@/views/knowledgeBase/courseManage/courseContentSetting.vue')
  }
]
