import store from '@/store'
import router from "@/router";

export default () => {
  // let menuList = router.option.routes;
  let permissionsList = JSON.parse(store.state.permissions)
  let routes = []
  let keepAlive = []

  const getAllPage = (modItem, modRoutes) => {
    if(modItem.children){
      modItem.children.forEach(menuItem => {
        let flag = false
        let itemInfo = { 
          path: menuItem.url, 
          name: menuItem.url.substr(1,menuItem.url.length),
          meta: {
            title: menuItem.name,
            buttons: []
          }
        }
        if(menuItem.children){
          flag = menuItem.children.every((item) => { return item.url.indexOf(":") != -1 })
          if(flag){
            menuItem.children.map((info) => {
              itemInfo.meta.buttons.push(info.url)
            })
            modRoutes.children.push(itemInfo)
          } else {
            itemInfo.children = []
            modRoutes.children.push(itemInfo)
            getAllPage(menuItem, modRoutes)
          }
        } else {
          modRoutes.children.push(itemInfo)
        }
      })
    }
  }

  permissionsList.forEach((modItem,i) => {
    if(modItem.url == '/system' || modItem.url == '/targetManage' || modItem.url == '/courseManage' || modItem.url == '/feedback' || modItem.url == '/coursePower'){
      let flag = false
      let modRoutes = {
        path: modItem.url,
        name: modItem.url.substr(1,modItem.url.length),
        meta: {
          title: modItem.name,
          buttons: []
        },
        icon: modItem.url == '/system' ? 'el-icon-s-tools' : ( modItem.url == '/targetManage' ? 'el-icon-s-cooperation' : ( modItem.url == '/courseManage' ? 'el-icon-s-order' : ( modItem.url == '/feedback' ? 'el-icon-s-promotion' : ( modItem.url == '/coursePower' ? 'el-icon-s-claim' : "" ) ) ))
      }
      if(modItem.children){
        flag = modItem.children.every((item) => { return item.url.indexOf(":") != -1 })
        if(flag){
          modItem.children.map((info) => {
            modRoutes.meta.buttons.push(info.url)
          })
        } else {
          modRoutes.children = []
          getAllPage(modItem, modRoutes)
        }
      }
      
      routes.push(modRoutes)
    }
  })
  store.state.keepAlive=keepAlive
  console.log(routes)
  // router.addRoutes(routes)
  store.commit('common/getMenuList', routes)
}

