import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '../views/door/login.vue'
import store from '@/store'

// import GoodsRouter from './modules/goods'
// import StockRouter from './modules/stock'
import KnowledgeBase from './modules/knowledgeBase'
import AdminRouter from './modules/admin'
import Feedback from './modules/feedback'
import {api} from "@/http/http.api";

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    hidden: true,
    component: login
  },
  {
    path: '/',
    name: 'index',
    hidden: true,
    component: () => import('@/layout/layout'),
    children: [
      ...AdminRouter,
      ...KnowledgeBase,
      ...Feedback,
    ]
  },
  {
    path: "*",
    redirect:"/"
  }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior: () => ({ y: 0 }),
    routes
})

router.afterEach(()=>{
    // api.getMyNotice({ isView: 0}).then(res=>{
    //     store.state.notice=res.data.data.total

    // })
})
// 解决重复点击路由报错的BUG
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err)
}

export default router
