import store from '@/store'

export default ()=>{
    store.dispatch('common/getCourseTypeList').then()//获取课程分类
    store.dispatch('common/getCourseFieldList').then() //获取课程领域
    store.dispatch('common/getCourseLevelList').then() //获取课程等级
    store.dispatch('common/getAgeScopeList').then() //获取年龄阶段
    store.dispatch('common/getActivityTypeList').then() //获取活动分类
    store.dispatch('common/getCourseThemeList').then() //获取课程专题
    store.dispatch('common/getCourseVersionList').then() //获取课程版本

    // store.dispatch('stock/getBaserList').then() //获取仓库列表
    // store.dispatch('stock/getSupplier').then() //获取供应商列表
    // store.dispatch('stock/getDeliveryStatus').then() //发货状态
    // store.dispatch('stock/getReviewStatus').then() //审核状态
    // store.dispatch('stock/getPaymentStatus').then() //付款状态
    // store.dispatch('stock/getAllShipType').then() //出货类型
    // store.dispatch('stock/getAllShipping').then() //配送方式
    // store.dispatch('stock/getAllPayment').then() //付款方式
    // store.dispatch('stock/getAllLine').then() //配送线路
    // store.dispatch('stock/getSettlement').then() //结算方式
    // store.dispatch('stock/getInvoiceType').then() //发票类型
    // store.dispatch('stock/getLevelOrder').then() //单据级别
    // store.dispatch('goods/getBrandList').then() //品牌列表
    // store.dispatch('goods/getAttrList').then() //品牌属性
    // store.dispatch('goods/getProdType').then() //干冻品
    // store.dispatch('goods/getWeightUnit').then() //重量单位
    // store.dispatch('goods/getBcList').then() //后台分类
    // store.dispatch('stock/getTransferReason').then()//调库原因

    // getAttrList
}
